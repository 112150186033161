<template>
  <div class="open-order">
    <div class="left">
      <div class="status yet-to-collect">{{ order.status }}</div>
      <div class="info-row">
        <div class="info">
          <div class="title">Collection Time</div>
          <div class="content">
            <div class="title">{{ formattedDate.monthDate }}{{ ',  ' + formattedDate.year }}</div>
            <p>{{ order.collectionTime }}</p>
          </div>
        </div>

        <div class="info">
          <div class="title">Collection address:</div>
          <div class="content">
            <div class="title">{{ order.collectionAddress.name }}</div>
            <p>
              <span>{{ order.collectionAddress.contactName }}</span>
              <span>{{ order.collectionAddress.contactMobile }}</span>
              <span>{{ order.collectionAddress.contactEmail }}</span>
            </p>
            <p>
              <span
                >{{ order.collectionAddress.primary }} {{ order.collectionAddress.secondary }} {{ order.collectionAddress.landmark }}, {{ order.collectionAddress.cityName }},
                {{ order.collectionAddress.zipCode }}, {{ order.collectionAddress.countryName }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="courier-company">
        <div class="logo" :style="{ 'background-image': order.selectedCompany.logoImage != null ? 'url(' + order.selectedCompany.logoImage + ')' : '' }"></div>
        <div class="tracking-number">{{ order.selectedCompany.name }}</div>
      </div>

      <div class="info">
        <div class="title">Destination address:</div>
        <div class="content">
          <div class="title">{{ order.destinationAddress.name }}</div>
          <p>
            <span>{{ order.destinationAddress.contactName }}</span>
            <span>{{ order.destinationAddress.contactMobile }}</span>
            <span>{{ order.destinationAddress.contactEmail }}</span>
          </p>
          <p>
            <span
              >{{ order.destinationAddress.primary }} {{ order.destinationAddress.secondary }} {{ order.destinationAddress.landmark }}, {{ order.destinationAddress.cityName }},
              {{ order.destinationAddress.zipCode }}, {{ order.destinationAddress.countryName }}</span
            >
          </p>
          <router-link :to="{ path: '/order/' + order.id }" class="btn">Details</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatpickr from 'flatpickr';

export default {
  name: 'OpenOrder',
  props: ['order'],
  data() {
    return {
      formattedDate: {
        monthDate: null,
        year: null,
      },
    };
  },
  mounted() {
    let collectionDate = flatpickr.parseDate(this.order.collectionDate, 'Y-m-d');
    this.formattedDate.monthDate = flatpickr.formatDate(collectionDate, 'F j');
    this.formattedDate.year = flatpickr.formatDate(collectionDate, 'Y');
  },
};
</script>

<style lang="scss">
@import '../../scss/partials/variables.scss';
.open-order {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.02);
  background-color: #fbfbfb;
  display: flex;
  margin-bottom: 20px;

  .left {
    flex: 0 0 (500/800 * 100%);
    padding: 20px;
  }

  .right {
    flex: 0 0 (300/800 * 100%);
    background-color: #fff;
    padding: 20px 20px 20px 40px;

    .info {
      padding: 0px;
    }
  }

  .status {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    color: #252423;
    padding: 8px 0px 8px 45px;
    margin-bottom: 30px;

    &.yet-to-collect {
      background-image: url($srcPath+'assets/yellow-yet-collect-icon.svg');
      background-repeat: no-repeat;
    }

    &.collected {
      background-image: url($srcPath+'assets/yellow-collected-icon.svg');
      background-repeat: no-repeat;
    }
  }

  .courier-company {
    margin-bottom: 30px;
    //padding-top: 8px;
    //padding-bottom: 8px;
  }

  .info-row {
    display: flex;
  }

  .info {
    padding: 0px 25px;
    flex: 1;

    &.delivery-date {
      text-align: center;
    }

    > .title {
      opacity: 0.5;
      font-size: 12px;
      color: #252423;
      margin-bottom: 15px;
    }

    .content {
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #252423;
      }

      p {
        font-size: 12px;
        color: #252423;
        margin: 5px 0px;

        span {
          display: block;
        }
      }

      .delivery-date {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(265deg, #725bba 91%, #7c4283 4%),
          linear-gradient(to bottom, #d8d8d8, #d8d8d8);
        font-size: 34px;
        font-weight: 600;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .btn {
        padding: 9px 50px;
        border-radius: 18px;
        box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
        background-color: #e79f00;
        margin-top: 30px;
        display: inline-block;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column-reverse;

    .right {
      padding-left: 20px;
    }

    .info-row {
      margin: 0px -10px;
    }

    .info {
      padding: 0px 10px;

      &.delivery-date {
        text-align: left;
      }
    }
  }

  @media (max-width: 400px) {
    .info-row {
      display: block;
    }

    .info {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
